import { useRouter } from "next/router";
import { useEffect } from "react";
import { useErrorHandler } from "react-error-boundary";
import { useGardenStore } from "stores/gardenStore";
import { usePlantsStore } from "stores/plantsStore";
import { UIViews, useStateStore } from "stores/stateStore";

interface Props {}

export const LoadGardenFromQuery = (props: Props) => {
  const handleError = useErrorHandler();
  const load = useGardenStore((state) => state.load);
  const changeView = useStateStore((state) => state.changeView);
  const fetchPlantsDb = usePlantsStore(state => state.fetch);
  const router = useRouter();
  const id = Array.isArray(router.query.id)
    ? router.query.id[0]
    : router.query.id;

  useEffect(() => {
    if (!id) return;
    (async () => {
      try {
        await fetchPlantsDb();
        await load(id);
        changeView(UIViews.LoadingGarden);
        await router.push("/pathmaker");
        // playSound();

      } catch (error) {
        router.replace("/", undefined, { shallow: true });
        handleError(error);
      }
      
    })();

    
    console.log(`loading scheme uuid = ${id}`);
  }, [id]);

  return null;
};
