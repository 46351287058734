
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import { fetchAPI, fetchMenu } from "../lib/api";
import { GetStaticProps } from "next";
import Layout from "../components/layout/layout";
import Link from "next/link";
import useTranslation from "next-translate/useTranslation";
import { Logo } from "components/Logo/Logo";
import { mq } from "styles/styles";
import { LoadGardenFromQuery } from "components/LoadGardenFromQuery/LoadGardenFromQuery";
import { ErrorModal } from "components/ErrorModal/ErrorModal";
import { ErrorBoundary } from "react-error-boundary";
import LanguageSwitcher from "components/LanguageSwitcher/LanguageSwitcher";

const HomePage = ({ title, intro, maincredit, credits, menu }) => {
  const { t } = useTranslation("common");
  return (
    <Layout title="" menu={menu}>
      <ErrorBoundary FallbackComponent={ErrorModal}>
        <LoadGardenFromQuery />
      </ErrorBoundary>
      <video
        className={"video-background"}
        poster="/pollinators-poster.jpg"
        autoPlay
        muted
        loop
        playsInline
      >
        <source src="/pollinators-homepage.mp4" type="video/mp4" />
      </video>
      <div className={"background-overlay"}></div>
      <div className={"content-wrapper"}>
        <div className={"content"}>
          <div className="LanguageSwitcher-holder">
            <LanguageSwitcher />
          </div>
          <div>
            <Logo />
            <h3
              className="maincredit"
              dangerouslySetInnerHTML={{ __html: maincredit }}
            />
          </div>
          <div>
            <div
              className="introduction"
              dangerouslySetInnerHTML={{ __html: intro }}
            />
            <Link href="/pathmaker" passHref>
              <a className={"button"}>{t("Start")}</a>
            </Link>
            <p className="credits" dangerouslySetInnerHTML={{ __html: credits }} />
          </div>
        </div>
      </div>

      <style jsx>{`
        .video-background {
          position: fixed;
          z-index: -1;
          width: 100vw;
          height: 100vh;
          top: 0;
          left: 0;
          object-fit: cover;
        }

        .background-overlay {
          display: block;
          position: fixed;
          z-index: 0;
          width: 100vw;
          height: 100vh;
          top: 0;
          left: 0;
          background: rgb(255,255,255);
          background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 5%, rgba(255,255,255,1) 100%);
        }

        @media ${mq.mediumUp} {
          .background-overlay {
            background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 40%, rgba(255,255,255,1) 100%);
          }
        }

        .content-wrapper {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
        }

        .content {
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          z-index: 10;
          position: relative;
          text-align: center;
          padding: 2rem;
        }

        .LanguageSwitcher-holder {
          position: absolute;
          top: var(--spacing);
          right: var(--spacing);
        }
        @media ${mq.mediumUp} {
          .LanguageSwitcher-holder {
            top: var(--spacing-2x);
            right: var(--spacing-2x);
          }
        }

        .maincredit {
          font: var(--font-heading-3);
          font-weight: normal;
          text-align: center;
          margin-top: 10rem;
        }
        @media ${mq.mediumUp} {
          .maincredit {
            margin-top: 14rem;
          }
        }

        .introduction {
          font-size: 2.25rem;
          padding-bottom: var(--spacing);
        }

        .button {
          display: inline-block;
          margin-bottom: 5rem;
          padding: 2rem 4rem;
          background-color: var(--purple);
          font: var(--font-heading-3);
          text-transform: uppercase;
          color: var(--white);
        }
        @media ${mq.mediumUp} {
          .button { 
            margin-bottom: var(--spacing-4x);
          }
        }
        .button:hover {
          background-color: var(--black);
        }
        
        .credits {    
          font: var(--font-caption);
          width: 100%;
          margin: 0;
          text-align: center;
        }
      `}</style>
    </Layout>
  );
};

 const _getStaticProps: GetStaticProps = async ({ locale }) => {
  const content = await fetchAPI(locale, "page('home')", {
    title: true,
    maincredit: true,
    credits: true,
    intro: "page.intro.kirbytext",
  });

  let menu = await fetchMenu(locale);

  return {
    props: {
      locale,
      menu,
      ...content,
    },
  };
};

export default HomePage;


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        let res = _getStaticProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  